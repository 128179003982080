<template>
  <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo.displayName">

    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ activeUserInfo.displayName }}</p>
      <small>Online</small>
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">

      <div class="con-img ml-3">
        <img v-if="activeUserInfo.photoURL" key="onlineImg" :src="activeUserInfo.photoURL" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block" />
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 10rem">
          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="changeTheme">
            <feather-icon :icon="activeMode == 'light' ? 'SunsetIcon' : 'SunriseIcon'" svgClasses="w-4 h-4" />
            <span class="ml-2" v-if="activeMode == 'light'">Dark Mode</span>
            <span class="ml-2" v-else>Light Mode</span>
          </li>
          <vs-divider></vs-divider>
          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Logout</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeMode: window.localStorage.getItem('themeMode') || 'light'
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    changeTheme () {
      if (this.activeMode === 'light') {
        window.localStorage.setItem('themeMode', 'dark')
      } else {
        window.localStorage.setItem('themeMode', 'light')
      }
      location.reload()
    },
    logout () {
      localStorage.removeItem('userInfo')
      window.eraseCookie('token')
      window.eraseCookie('username')
      this.$router.push('/login').catch(() => {})
    }
  }
}
</script>
