/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
  {
    url: '/',
    name: 'Dashboard',
    slug: 'dashboard',
    icon: 'ActivityIcon'
  },
  {
    url: null,
    name: 'Gestione abbonati',
    slug: 'gestione-abbonati',
    icon: 'FileTextIcon',
    submenu: [
      {
        url: '/gestione-abbonamenti/cerca-utente',
        name: 'Cerca utente',
        slug: 'cerca-utente'
      },
      {
        url: '/gestione-abbonamenti/lista-abbonati',
        name: 'Lista abbonati',
        slug: 'lista-abbonati'
      },
      {
        url: '/gestione-abbonamenti/liste-omaggio',
        name: 'Liste omaggio',
        slug: 'liste-omaggio'
      },
      {
        url: '/gestione-abbonamenti/cgr',
        name: 'File CGR',
        slug: 'file-cgr'
      },
      {
        url: '/gestione-abbonamenti/rispedizioni-riviste',
        name: 'Rispedizioni riviste',
        slug: 'rispedizioni-riviste'
      },
      {
        url: '/gestione-abbonamenti/rispedizioni-libri-omaggio',
        name: 'Rispedizioni libri',
        slug: 'rispedizioni-libri'
      }
    ]
  },
  {
    url: '/etichette-editoria',
    name: 'Etichette editoria',
    slug: 'etichette-editoria',
    icon: 'BookIcon'
  },
  {
    url: '/banner-pubblicitari',
    name: 'Banner pubblicitari',
    slug: 'banner-pubblicitari',
    icon: 'MonitorIcon'
  },
  {
    url: '/tickets',
    name: 'Ticket Eventi',
    slug: 'tickets',
    icon: 'TagIcon'
  }
]
